<template>
	<CRow class="justify-content-center">
		<CCol md="12">
			<loading-screen v-if="isLoading" :information-text="textLoading" />
			<CCard>
				<CCardHeader style="background: #97ca3d;color: #000000;">
					<h4>{{ tittleForm }}</h4>
				</CCardHeader>
				<CCardBody>
					<ValidationObserver ref="observer" v-slot="{ invalid }">
						<CAlert v-if="errorsFound" color="danger"
							>Oops!! El formulario tiene los siguientes errores:
							<b>{{ errorsFound }}</b></CAlert
						>
						<CForm>
							<ValidationProvider
								name="Nombre"
								rules="required"
								v-slot="{ valid, errors, touched }"
							>
								<CInput
									v-model="dataUser.name"
									placeholder="Nombre"
									label="Nombre del Usuario"
									:isValid="
										touched && !valid
											? valid
											: touched && valid
											? valid
											: null
									"
								>
									<template #prepend-content>
										<i class="fas fa-user-tie"></i
									></template>
									<template #invalid-feedback>
										<span class="invalid-feedback">{{
											errors[0]
										}}</span>
									</template>
									<template #description>
										<span class="style-description"
											>Ingresa el nombre completo del
											usuario.</span
										>
									</template>
								</CInput>
							</ValidationProvider>
							<ValidationProvider
								name="E-Mail"
								rules="required|email"
								v-slot="{ valid, errors, touched }"
							>
								<CInput
									v-model="dataUser.email"
									placeholder="E-Mail"
									label="E-Mail"
									:isValid="
										touched && !valid
											? valid
											: touched && valid
											? valid
											: null
									"
								>
									<template #prepend-content>
										<i class="fas fa-at"></i>
									</template>
									<template #invalid-feedback>
										<span class="invalid-feedback">{{
											errors[0]
										}}</span>
									</template>
									<template #description>
										<span class="style-description"
											>Este será el login para ingresar al
											sistema.</span
										>
									</template>
								</CInput>
							</ValidationProvider>
							<ValidationProvider
								name="Contraseña"
								rules="required|min:6"
								v-slot="{ valid, errors, touched }"
								vid="password"
							>
								<CInput
									v-model="dataUser.password"
									placeholder="Contraseña"
									type="password"
									autocomplete="new-password"
									label="Contraseña"
									:isValid="
										touched && !valid
											? valid
											: touched && valid
											? valid
											: null
									"
								>
									<template #prepend-content>
										<i class="fas fa-lock"></i
									></template>
									<template #invalid-feedback>
										<span class="invalid-feedback">{{
											errors[0]
										}}</span>
									</template>
								</CInput>
							</ValidationProvider>
							<ValidationProvider
								name="Contraseña"
								rules="required|confirmed:password|min:6"
								v-slot="{ valid, errors, touched }"
							>
								<CInput
									v-model="dataUser.password_confirmation"
									placeholder="Repite la contraseña"
									type="password"
									autocomplete="new-password"
									label="Repetir Contraseña"
									class="mb-4"
									:isValid="
										touched && !valid
											? valid
											: touched && valid
											? valid
											: null
									"
								>
									<template #prepend-content>
										<i class="fas fa-lock"></i
									></template>
									<template #invalid-feedback>
										<span class="invalid-feedback">{{
											errors[0]
										}}</span>
									</template>
								</CInput>
							</ValidationProvider>
							<CRow
								form
								class="form-group"
								style="margin-left:1px;"
							>
								<label for="urb">Perfil del Usuario</label>
								<multiselect
									v-model="userSelectedProfile"
									:preselect-first="true"
									@select="changeProfile"
									v-if="allProfiles != null"
									:allow-empty="false"
									track-by="idProfiles"
									label="name"
									:options="allProfiles"
									:searchable="true"
									:close-on-select="true"
									:show-labels="false"
									placeholder="Seleccione"
								>
									<span slot="noResult"
										>Oops! No se encontró la opción
										deseada</span
									>
								</multiselect>
							</CRow>
							<CRow class="content-center">
								<CCol sm="12">
									<CRow class="content-right">
										<CCol sm="12">
											<b-button
												@click="saveUser"
												title="Guardar"
												id="sendMail"
												:disabled="invalid"
												pill
												variant="success"
												class="mr-1 text-white"
											>
												Guardar
											</b-button>
											<b-button
												title="Cancelar"
												id="goBack"
												@click="goBack"
												pill
												variant="warning"
												class="mr-1 text-white"
											>
												Cancelar
											</b-button>
										</CCol>
									</CRow>
								</CCol>
							</CRow>
						</CForm>
					</ValidationObserver>
				</CCardBody>
			</CCard>
		</CCol>
		<success-modal
			v-if="modalSuccessSend"
			:trigger-modal="true"
			:redirect="true"
			:route-to-redirect="modalCloseRedirect"
			:title-modal="modalTitle"
			:textModal="modalText"
		/>
	</CRow>
</template>

<script>
	import '../../validations/validationsForm';
	import adminUsers from '../../functions/functionsAdminUsers';
	import loadingScreen from '../loadingScreen.vue';
	import SuccessModal from '../modals/successModal.vue';
	export default {
		components: { loadingScreen, SuccessModal },
		name: 'CreateUser',
		props: {
			editUser: {
				type: Boolean,
				default: function() {
					return false;
				},
			},
			tittleForm: {
				type: String,
				default: function() {
					return 'Crear Nuevo Usuario';
				},
			},
			idUser: {
				type: Number,
				default: function() {
					return 0;
				},
			},
		},
		data() {
			return {
				dataUser: {
					idUsers: '',
					name: '',
					email: '',
					password: '',
					password_confirmation: '',
					idprofiles: '',
				},
				userSelectedProfile: null,
				allProfiles: null,
				errorsFound: '',
				modalSuccessSend: false,
				isLoading: false,
				textLoading: '',
				modalCloseRedirect: 'AllUsers',
				modalTitle: '',
				modalText: '',
			};
		},
		created() {
			this.loadProfiles();
			if (this.editUser) {
				this.loadUser();
			}
		},
		methods: {
			name() {},
			changeProfile(selectedProfile) {
				this.dataUser.idprofiles = selectedProfile.idProfiles;
			},
			loadProfiles() {
				this.isLoading = true;
				this.textLoading = 'Cargando perfiles, por favor espere';
				adminUsers
					.getAllProfiles()
					.then((response) => {
						this.allProfiles = response;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			loadUser() {
				this.isLoading = true;
				this.textLoading =
					'Cargando datos del usuario, por favor espere';
				adminUsers
					.getSpecificUser(this.idUser)
					.then((response) => {
						this.dataUser.name = response[0].name;
						this.dataUser.email = response[0].email;
						this.dataUser.idprofiles = response[0].id_profiles;
						this.dataUser.idUsers = response[0].idUsers;
						this.userSelectedProfile = response[0].profile;
					})
					.catch((error) => {
						console.log(error);
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			goBack() {
				if (!this.editUser) {
					this.$router.push({
						name: 'Dashboard',
					});
				} else {
					this.$router.push({
						name: 'AllUsers',
					});
				}
			},
			saveUser() {
				this.errorsFound = '';
				let dataForPost = {
					name: this.dataUser.name,
					email: this.dataUser.email,
					id_profiles: this.dataUser.idprofiles,
					password: this.dataUser.password,
					password_confirmation: this.dataUser.password_confirmation,
				};
				if (!this.editUser) {
					this.newUser(dataForPost);
				} else {
					this.updateUser(dataForPost);
				}
			},
			newUser(dataForPost) {
				this.isLoading = true;
				this.textLoading = 'Guardando el usuario, por favor espere';
				this.modalTitle = 'Creado Exitosamente';
				this.modalText =
					'Has creado exitosamente un nuevo usuario para <b>' +
					dataForPost.name +
					'</b>';
				adminUsers
					.postNewUser(dataForPost)
					.then((response) => {
						this.modalSuccessSend = true;
					})
					.catch((error) => {
						console.log(error);
						let errorCode = error.response.data.code;
						switch (errorCode) {
							case 422:
								for (const [key, value] of Object.entries(
									error.response.data.error
								)) {
									this.errorsFound += value + ' ';
								}
								break;
						}
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			updateUser(dataForPost) {
				this.isLoading = true;
				this.textLoading = 'Actualizando el usuario, por favor espere';
				this.modalTitle = 'Editado Exitosamente';
				this.modalText =
					'Has actualizado exitosamente los datos del usuario para: <b>' +
					dataForPost.name +
					'</b>';
				adminUsers
					.putEditUser(this.dataUser.idUsers, dataForPost)
					.then((response) => {
						this.modalSuccessSend = true;
					})
					.catch((error) => {
						console.log(error);
						let errorCode = error.response.data.code;
						switch (errorCode) {
							case 422:
								for (const [key, value] of Object.entries(
									error.response.data.error
								)) {
									this.errorsFound += value + ' ';
								}
								break;
						}
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
		},
	};
</script>

<style></style>
